// smooth scroll
import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

const isSP = () => {
  let bool;

  if(window.innerWidth < 980) {
    bool = true;
  } else {
    bool = false;
  };

  return bool;
}

// ローディングアニメーション
function loadingAnimation() {
  const loading = document.querySelector(".loading");
  const loadingGreen = document.querySelector(".loading-bg-green");
  const loadingYellow = document.querySelector(".loading-bg-yellow");

  setTimeout(() => {
    loadingGreen.style.animationName = "slide-in-1";

    loadingGreen.addEventListener("animationend", () => {
      loadingYellow.style.animationName = "slide-in-1";
    });

    loadingYellow.addEventListener("animationend", () => {
      loading.style.animationName = "slide-in-2";
    })
  }, 800);
}

// サイドナビゲーションの追従設定
function sideNavigation() {
  const sideNavigation = document.querySelector(".side-navigation");
  const mainVisual = document.getElementById("top");

  function control() {
    const scrollPosition = window.scrollY;

    if(mainVisual.clientHeight <= scrollPosition) {
      sideNavigation.style.transform = "translateY(-50%) translateX(0)";
    } else {
      sideNavigation.style.transform = "translateY(-50%) translateX(100%)";
    }
  }

  window.addEventListener("scroll", control);
  control()
}

// サイドナビゲーションのコントロール
function spNavigationControl() {
  const navigationWrapper = document.getElementById("full-screen-navigation");
  
  navigationWrapper.addEventListener("click", control);
  
  function control() {
    const checkbox = document.getElementById("full-screen-navigation-control");
    if(checkbox.checked) {
      navigationWrapper.style.transform = "translateX(100%)";
      checkbox.checked = false;
    }
  }
}

// トップページのスライドショー
function topSlider() {
  const slider = new KeenSlider("#top-slider", {
    loop: true,
    defaultAnimation: {
      duration: 2000
    },
    detailsChanged: (s) => {
      s.slides.forEach((element, idx) => {
        element.style.opacity = s.track.details.slides[idx].portion
      });
    },
    renderMode: "custom",
    drag: false
  },
  [
    (slider) => {
      function nextTimeout() {
        setTimeout(() => {
          slider.next()
        }, 2000)
      }
      slider.on("created", () => {
        nextTimeout()
      })
      slider.on("animationEnded", nextTimeout)
      slider.on("updated", nextTimeout)
    },
  ]);
}

// レシピスライダー
function recipeSlider() {
  const slider = new KeenSlider('#recipe__slider', {
    slides: {
      spacing: 48
    },
    renderMode: "performance",
    created: (slider) => {
      const dotWrapper = document.querySelector(".recipe-slider-navigation-dots");

      for(let i = 0; i < slider.slides.length; i++) {
        if(i == 0) {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='recipe-slider-navigation-dot recipe-slider-navigation-dot--active'></div>");
        } else {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='recipe-slider-navigation-dot'></div>");
        }
      }
    },
    animationStarted: () => {
      const dot = document.querySelectorAll(".recipe-slider-navigation-dot");

      dot.forEach((e) => {
        e.classList.remove("recipe-slider-navigation-dot--active");
      });
    },
    animationEnded: (slider) => {
      const sliderIdx = slider.track.details.abs;
      const dot = document.querySelectorAll(".recipe-slider-navigation-dot");
      const nextBtn = document.querySelector('.recipe-slider-navigation-next');
      const prevBtn = document.querySelector('.recipe-slider-navigation-prev');

      dot.forEach((e,i) => {
        if(sliderIdx == i) {
          e.classList.add("recipe-slider-navigation-dot--active");
        }
      });

      if(sliderIdx !== 0) {
        prevBtn.removeAttribute("hidden");
      } else if(sliderIdx == 0) {
        prevBtn.setAttribute("hidden", "");
      }

      if(sliderIdx == (slider.slides.length - 1)) {
        nextBtn.setAttribute("hidden", "");
      } else {
        nextBtn.removeAttribute("hidden");
      }
    }
  },
  [
    (slider) => {
      const prevBtn = document.querySelector('.recipe-slider-navigation-prev');
      const nextBtn = document.querySelector('.recipe-slider-navigation-next');

      prevBtn.addEventListener("click", () => {
        slider.prev();
      });

      nextBtn.addEventListener("click", () => {
        slider.next();
      });
    }
  ]);
}

// tips スライダー
function tipsSlider() {
  const slider = new KeenSlider("#tips__slider", {
    slides: {
      spacing: 48
    },
    renderMode: "performance",
    created: (slider) => {
      const dotWrapper = document.querySelector(".tips-slider-navigation-dots");
      // ナビゲーションのドットを挿入
      for(let i = 0; i < slider.slides.length; i++) {
        if(i == 0) {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='tips-slider-navigation-dot tips-slider-navigation-dot--active'></div>");
        } else {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='tips-slider-navigation-dot'></div>");
        }
      }
    },
    // ドットの状態をリセット
    animationStarted: () => {
      const dot = document.querySelectorAll(".tips-slider-navigation-dot");

      dot.forEach((e) => {
        e.classList.remove("tips-slider-navigation-dot--active");
      });
    },
    // アクティブな位置のドットに色をつける
    animationEnded: (slider) => {
      const sliderIdx = slider.track.details.abs;
      const dot = document.querySelectorAll(".tips-slider-navigation-dot");
      const nextBtn = document.querySelector('.tips-slider-navigation-next');
      const prevBtn = document.querySelector('.tips-slider-navigation-prev');

      dot.forEach((e,i) => {
        if(sliderIdx == i) {
          e.classList.add("tips-slider-navigation-dot--active");
        }
      });

      if(sliderIdx !== 0) {
        prevBtn.removeAttribute("hidden");
      } else if(sliderIdx == 0) {
        prevBtn.setAttribute("hidden", "");
      }

      if(sliderIdx == (slider.slides.length - 1)) {
        nextBtn.setAttribute("hidden", "");
      } else {
        nextBtn.removeAttribute("hidden");
      }
    }
  },
  [
    (slider) => {
      const prevBtn = document.querySelector('.tips-slider-navigation-prev');
      const nextBtn = document.querySelector('.tips-slider-navigation-next');
      const dot = document.querySelectorAll('.tips-slider-navigation-dot');

      prevBtn.addEventListener("click", () => {
        slider.prev();
      });

      nextBtn.addEventListener("click", () => {
        slider.next();
      });
    }
  ]);
}

// charm slider
function charmSlider() {
  const slider = new KeenSlider("#charm-slider", {
    slides: {
      spacing: 48
    },
    created: (slider) => {
      const dotWrapper = document.querySelector(".charm-slider-navigation-dots");
      // ナビゲーションのドットを挿入
      for(let i = 0; i < slider.slides.length; i++) {
        if(i == 0) {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='charm-slider-navigation-dot charm-slider-navigation-dot--active'></div>");
        } else {
          dotWrapper.insertAdjacentHTML("beforeend", "<div class='charm-slider-navigation-dot'></div>");
        }
      }
    },
    // ドットの状態をリセット
    animationStarted: () => {
      const dot = document.querySelectorAll(".charm-slider-navigation-dot");

      dot.forEach((e) => {
        e.classList.remove("charm-slider-navigation-dot--active");
      });
    },
    // アクティブな位置のドットに色をつける
    animationEnded: (slider) => {
      const sliderIdx = slider.track.details.abs;
      const dot = document.querySelectorAll(".charm-slider-navigation-dot");
      const nextBtn = document.querySelector('.charm-slider-navigation-next');
      const prevBtn = document.querySelector('.charm-slider-navigation-prev');

      dot.forEach((e,i) => {
        if(sliderIdx == i) {
          e.classList.add("charm-slider-navigation-dot--active");
        }
      });

      if(sliderIdx !== 0) {
        prevBtn.removeAttribute("hidden");
      } else if(sliderIdx == 0) {
        prevBtn.setAttribute("hidden", "");
      }

      if(sliderIdx == (slider.slides.length - 1)) {
        nextBtn.setAttribute("hidden", "");
      } else {
        nextBtn.removeAttribute("hidden");
      }
    }
  },
  [
    (slider) => {
      const prevBtn = document.querySelector('.charm-slider-navigation-prev');
      const nextBtn = document.querySelector('.charm-slider-navigation-next');
      const dot = document.querySelectorAll('.charm-slider-navigation-dot');

      prevBtn.addEventListener("click", () => {
        slider.prev();
      });

      nextBtn.addEventListener("click", () => {
        slider.next();
      });
    }
  ]);
}

// calendar slider
function calendarSlider() {
  sliderSetUp()
  window.addEventListener('resize', sliderSetUp)

  function sliderSetUp() {
    const slider = new KeenSlider("#calendar__main", {
      loop: true,
      mode: "free-snap",
      slides: {
        perView: isSP() ? 1 : 3.1,
        spacing: isSP() ? 120 : 80
      },
      renderMode: "precision"
    });
  }
}

window.addEventListener("load", () => {
  loadingAnimation();
  sideNavigation();
  topSlider();
  recipeSlider();
  tipsSlider();
  calendarSlider();
  charmSlider();
  spNavigationControl();
});